import { Logo } from "../../components/Logo";
import { useHistory } from "react-router";
import { ClaimableTicketsCard } from "./components/ClaimableTicketsCard";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../app/hooks";
import {
  claimRaffleTickets,
  getLottoTickets,
  getRaffleTickets,
  selectLotto,
} from "./lottoSlice";
import { useEffect } from "react";
import { ClaimRaffleTicketButton } from "./components/ClaimRaffleTicketButton";
import { EnterLottoNumbersButton } from "./components/EnterLottoNumbersButton";
import { selectAuth } from "../auth/authSlice";

const UserPage = () => {
  const history = useHistory();
  const { user } = useAppSelector(selectAuth);
  const {
    isLoading,
    lottoTickets,
    raffleTickets,
    claimableLottoTickets,
    claimableRaffleTickets,
  } = useAppSelector(selectLotto);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getLottoTickets());
    dispatch(getRaffleTickets());
  }, [dispatch]);

  // Handle Loading
  if (isLoading) {
    return null;
  }

  const onClaimRaffleTicketsPressed = () => {
    dispatch(claimRaffleTickets());
  };

  const onEnterLottoNumbersPressed = () => {
    history.push("/lotto/fill-in");
  };

  const onRedeemedRaffleTicketsPressed = () => {
    history.push("/lotto/raffle-tickets");
  };
  const onRedeemedLottoTicketsPressed = () => {
    history.push("/lotto/view");
  };

  const renderRedemeedTickets = () => {
    const hasRedeemedTickets =
      lottoTickets.length !== 0 || raffleTickets.length !== 0;
    if (!hasRedeemedTickets) {
      return null;
    }

    return (
      <div className="flex flex-col w-full gap-4">
        <span>Here are your redeemed tickets</span>
        <ClaimableTicketsCard
          isRaffleTicket={true}
          label={`${raffleTickets.length} raffle tickets`}
          tickets={raffleTickets}
          onClick={onRedeemedRaffleTicketsPressed}
        />
        <ClaimableTicketsCard
          isRaffleTicket={false}
          label={`${lottoTickets.length} lottery ticket`}
          tickets={lottoTickets}
          onClick={onRedeemedLottoTicketsPressed}
        />
      </div>
    );
  };

  const renderClaimableTickets = () => {
    return (
      <div className="flex flex-col w-full gap-3">
        <p>
          You have
          <span className="font-bold text-lotto-orange">
            {` ${claimableRaffleTickets} `}
          </span>
          raffle tickets and
          <span className="font-bold text-lotto-orange">
            {` ${claimableLottoTickets} `}
          </span>
          lottery ticket to redeem.
        </p>
        <ClaimRaffleTicketButton
          onClick={onClaimRaffleTicketsPressed}
          claimableRaffleTickets={claimableRaffleTickets}
        />
        <EnterLottoNumbersButton
          claimableLottoTickets={claimableLottoTickets}
          onClick={onEnterLottoNumbersPressed}
        />
      </div>
    );
  };

  return (
    <div className="flex justify-center w-full h-full gap-8 p-4 pt-20 ">
      <div className="flex flex-col items-center w-full max-w-md gap-4">
        <Logo />
        <div className="flex flex-col w-full">
          <h1 className="text-2xl font-bold">Hi {user?.account_name},</h1>
        </div>
        {renderRedemeedTickets()}
        {renderClaimableTickets()}
      </div>
    </div>
  );
};

export default UserPage;
