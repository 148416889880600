import AwardsGraphic from "../../assets/images/winners-amico.svg";

const AvailablePrizesSection = () => {
  return (
    <section className="grid w-full grid-cols-1 px-4 py-10 md:grid-cols-2 md:px-20 md:py-20">
      <div className="items-center hidden md:flex">
        <img src={AwardsGraphic} alt="" />
      </div>
      <div className="flex flex-col items-center justify-start w-full h-full gap-8 px-4">
        <h1 className="text-3xl font-bold">Available Prizes</h1>
        <div className="flex flex-col items-center w-full gap-4">
          <span className="text-2xl font-bold text-lotto-orange">
            LOTTO PRIZE
          </span>
          <h1 className="text-4xl font-bold text-red-700 md:text-7xl">
            ₱500,000
          </h1>
        </div>
        <div className="flex flex-col items-center w-full gap-4">
          <span className="text-2xl font-bold text-lotto-orange">
            RAFFLE PRIZES
          </span>
          <h1 className="text-3xl font-bold text-center text-lotto-dark-green md:text-6xl">
            1 Motorcycle
          </h1>

          <span>and</span>
          <h1 className="text-3xl font-bold text-center text-lotto-dark-green md:text-6xl">
            2 Axie Infinity Scholarships
          </h1>
        </div>
      </div>
      <img className="md:hidden" src={AwardsGraphic} alt="" />
    </section>
  );
};

export default AvailablePrizesSection;
