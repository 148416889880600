import { OutlineButton } from "../../../components/Buttons";
import ArrowRight from "../../../assets/images/arrow-right.svg";

interface IProps {
  claimableRaffleTickets: number;
  onClick: () => void;
}

export const ClaimRaffleTicketButton = ({
  claimableRaffleTickets,
  onClick,
}: IProps) => {
  // Don't render if there is no claimable tickets
  if (claimableRaffleTickets === 0) {
    return null;
  }

  return (
    <OutlineButton
      onClick={onClick}
      className="flex justify-between p-3 transition duration-300 border border-gray-300 rounded-lg hover:bg-gray-200 focus:border-lotto-green"
    >
      <span>Claim Raffle Tickets</span>
      <img src={ArrowRight} alt="arrow right" />
    </OutlineButton>
  );
};
