import { createReducer } from "@reduxjs/toolkit";
import { hideModal, showModal } from "./actions";

export interface ModalState {
  isVisible: boolean;
  modalProps: any;
  modalName: any;
}

const initialState: ModalState = {
  isVisible: false,
  modalName: null,
  modalProps: null,
};

export const modalReducer = createReducer(initialState, {
  [showModal.type]: (state, action) => {
    if (showModal.match(action)) {
      state.isVisible = true;
      state.modalProps = action.payload.modalProps;
      state.modalName = action.payload.modalName;
    }
  },
  [hideModal.type]: (state) => {
    state.isVisible = false;
    state.modalName = null;
    state.modalProps = null;
  },
});
