import { createReducer } from "@reduxjs/toolkit";
import {
  claimRaffleTickets,
  getLottoTickets,
  getRaffleTickets,
  resetSelectedDigits,
  setSelectedDigit,
  submitLottoNumber,
} from ".";
import { toast } from "react-toastify";
import { RaffleTicket } from "../types/RaffleTicket";
import { LottoTicket } from "../types/LottoTicket";

export interface LottoState {
  ticketNumber?: string | null;
  selectedDigits: number[];
  selectedLottoTicket?: LottoTicket | null;
  raffleTickets: RaffleTicket[];
  claimableRaffleTickets: number;
  lottoTickets: LottoTicket[];
  claimableLottoTickets: number;
  error?: any;
  isLoading: boolean;
  success: boolean;
}

const initialState: LottoState = {
  selectedDigits: [],
  selectedLottoTicket: null,
  lottoTickets: [],
  raffleTickets: [],
  claimableLottoTickets: 0,
  claimableRaffleTickets: 0,
  ticketNumber: null,
  isLoading: true,
  success: false,
};

export const lottoReducer = createReducer(initialState, {
  [setSelectedDigit.type]: (state, action) => {
    if (!setSelectedDigit.match(action)) {
      return;
    }

    const isExisting = state.selectedDigits.find(
      (num) => num === action.payload
    );

    if (isExisting) {
      state.selectedDigits = state.selectedDigits.filter(
        (num) => num !== action.payload
      );
      return;
    }

    if (state.selectedDigits.length === 3) {
      toast("You can only select 3 digits", {
        type: "warning",
      });
      return;
    }
    state.selectedDigits.push(action.payload);
  },
  [resetSelectedDigits.type]: (state) => {
    state.selectedDigits = [];
  },
  [getLottoTickets.pending.type]: (state) => {
    state.isLoading = true;
    state.success = false;
  },
  [getLottoTickets.fulfilled.type]: (state, action) => {
    state.isLoading = false;
    state.lottoTickets = action.payload.tickets;
    state.claimableLottoTickets = action.payload.claimable;
    state.success = true;
  },
  [getLottoTickets.rejected.type]: (state, action) => {
    state.isLoading = false;
    state.error = action.payload.message;
    state.success = false;
  },
  [submitLottoNumber.pending.type]: (state) => {
    state.success = false;
  },
  [submitLottoNumber.fulfilled.type]: (state, action) => {
    state.lottoTickets = action.payload;
    state.claimableLottoTickets -= 1;
    state.success = true;

    toast("You have successfully submitted a lotto ticket!", {
      type: "success",
    });
  },
  [submitLottoNumber.rejected.type]: (state, action) => {
    state.success = false;
    state.error = action.error.message;

    toast("Something bad happened while submitting...");
  },
  [getRaffleTickets.pending.type]: (state) => {
    state.isLoading = true;
    state.success = false;
  },
  [getRaffleTickets.fulfilled.type]: (state, action) => {
    state.isLoading = false;
    state.raffleTickets = action.payload.tickets;
    state.claimableRaffleTickets = action.payload.claimable;
    state.success = true;
  },
  [getRaffleTickets.rejected.type]: (state, action) => {
    state.isLoading = false;
    state.success = false;
    state.error = action.payload.message;
  },

  [claimRaffleTickets.fulfilled.type]: (state, action) => {
    state.isLoading = false;
    state.raffleTickets = action.payload;
    state.claimableRaffleTickets = 0;
    state.success = true;

    toast("Raffle Tickets Claimed", {
      type: "success",
    });
  },
  [claimRaffleTickets.rejected.type]: (state, action) => {
    state.isLoading = false;
    state.success = false;
    state.error = action.payload.message;

    toast("Something bad happened while claiming...");
  },
});
