import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import AvailablePrizesSection from "./AvailablePrizesSection";
import EndSection from "./EndSection";
import { getHomePageData, selectHome } from "./homeSlice";
import HowToIncreaseSection from "./HowToIncreaseSection";
import HowToJoinSection from "./HowToJoinSection";
import LandingSection from "./LandingSection";
import ProgressSection from "./ProgressSection";

const HomePage = () => {
  // Fetch the homepage data

  const { isLoading } = useAppSelector(selectHome);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getHomePageData());
  }, [dispatch]);

  const renderContent = () => {
    if (isLoading) {
      return null;
    }
    return (
      <>
        {/* Hero Page */}
        <LandingSection />
        {/* Lotto Page */}
        <ProgressSection />
        {/* Available Prizes */}
        <AvailablePrizesSection />
        {/* How to Join */}
        <HowToJoinSection />
        {/* Chances of Winning */}
        <HowToIncreaseSection />
        {/* Tara na */}
        <EndSection />
        {/* Footer */}
        <div className="flex items-center justify-center w-full p-4">
          <span className="text-2xl md:text-base">
            Copyright © Sarisuki Pte Ltd. 2021
          </span>
        </div>
      </>
    );
  };

  return <div className="flex flex-col w-full pt-8">{renderContent()}</div>;
};

export default HomePage;
