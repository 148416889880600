import { createReducer } from "@reduxjs/toolkit";
import { checkAuth, forgotPassword, login } from ".";
import { UserStore } from "./types";

export interface AuthState {
  isLoggedIn: boolean;
  user?: UserStore | null;
  success?: boolean;
  isLoading: boolean;
  error?: any;
}
const initialState: AuthState = {
  isLoggedIn: false,
  isLoading: false,
  success: false,
  user: null,
  error: null,
};

export const authReducer = createReducer(initialState, {
  [login.fulfilled.type]: (state, action) => {
    state.isLoading = false;
    state.isLoggedIn = true;
    state.user = action.payload;
  },
  [login.rejected.type]: (state, action) => {
    state.isLoading = false;
    state.isLoggedIn = false;
    state.user = null;
    state.error = action.error.message;
  },
  [login.pending.type]: (state, action) => {
    state.isLoading = true;
    state.success = false;
  },
  [checkAuth.pending.type]: (state) => {
    state.isLoading = true;
    state.success = false;
  },
  [checkAuth.fulfilled.type]: (state, action) => {
    state.isLoading = false;
    state.isLoggedIn = true;
    state.user = action.payload;
  },
  [checkAuth.rejected.type]: (state, action) => {
    state.isLoading = false;
    state.isLoggedIn = false;
    state.user = null;
    state.error = action.error.message;
  },
  [forgotPassword.pending.type]: (state, action) => {
    state.isLoading = true;
    state.success = false;
  },
  [forgotPassword.fulfilled.type]: (state, action) => {
    state.isLoading = false;
    state.success = true;
  },
  [forgotPassword.rejected.type]: (state, action) => {
    state.isLoading = false;
    state.success = false;
  },
});
