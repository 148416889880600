import HowToJoin1 from "../../assets/images/how-to-join-1.svg";
import HowToJoin2 from "../../assets/images/how-to-join-2.svg";
import HowToJoin3 from "../../assets/images/how-to-join-3.svg";

const HowToJoinSection = () => {
  return (
    <section className="flex flex-col items-center w-full gap-4 px-4 py-10">
      <h1 className="text-4xl font-bold">How to Join</h1>
      <div className="flex flex-col gap-8 md:grid md:grid-cols-3">
        <div className="flex flex-col items-center w-full">
          <img src={HowToJoin1} alt="how to join 1" />
          <p className="text-center md:text-2xl">
            Get 80 CLs to hit ₱80,000 sales target for October
          </p>
        </div>
        <div className="flex flex-col items-center w-full">
          <img src={HowToJoin2} alt="how to join 1" />
          <p className="text-center md:text-2xl">
            Choose your lottery numbers (3 digits, from 0 to 20) and collect
            your raffle tickets on Nov 1
          </p>
        </div>
        <div className="flex flex-col items-center w-full">
          <img src={HowToJoin3} alt="how to join 1" />
          <p className="text-center md:text-2xl">
            Join our Lotto & Raffle Draw on Nov 7 (Sunday) 2:30 PM
          </p>
        </div>
      </div>
    </section>
  );
};

export default HowToJoinSection;
