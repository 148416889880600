import Background from "../../assets/images/background.png";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { checkStoreEligibility, selectHome } from "./homeSlice";
import { Store } from "./homeSlice/types";
import { HomeTicketGrid } from "./components/HomeTicketGrid";
import { selectLotto } from "../lotto/lottoSlice";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { phoneNumberSchema } from "../schemas/phoneNumberSchema";
import LockImage from "../../assets/images/padlock.png";
import { useState } from "react";
import { ApiResponse } from "../../types/api_response";
import { useHistory } from "react-router";

interface CheckEligibilityInput {
  phoneNumber: string;
}

const ProgressSection = () => {
  const { data } = useAppSelector(selectHome);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isEligible, setIsEligible] = useState<boolean | null>(null);
  const [apiError, setApiError] = useState<string | null>(null);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const renderTicketGrids = () => {
    if (!data) {
      return;
    }
    const categories: Record<string, string> = {
      eligible_stores: "80,000 and Above",
      sixty_up_stores: "60,000 to 79,999",
      forty_up_stores: "40,000 to 59,999",
      ten_up_stores: "10,000 to 39,999",
    };

    const ticketGrids: Record<string, Store[]> = {
      eligible_stores: data.eligible_stores,
      sixty_up_stores: data.sixty_up_stores,
      forty_up_stores: data.forty_up_stores,
      ten_up_stores: data.ten_up_stores,
    };

    return Object.keys(ticketGrids).map((key) => {
      const stores = ticketGrids[key];
      const label = categories[key];
      return <HomeTicketGrid label={label} stores={stores} key={label} />;
    });
  };
  const onClaimTicketsPressed = () => {
    history.push("/auth/login");
  };

  const onMobileNumberSubmitted = async (data: CheckEligibilityInput) => {
    try {
      setIsEligible(null);
      setApiError(null);
      setIsLoading(true);
      const response = await dispatch(
        checkStoreEligibility(data.phoneNumber)
      ).unwrap();

      if (!response.data) {
        setApiError("You are not eligible");
      } else {
        setIsEligible(response.data);
      }

      setIsLoading(false);
    } catch (error) {
      setApiError("You are not eligible");
      setIsEligible(null);
      setIsLoading(false);
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CheckEligibilityInput>({
    resolver: yupResolver(phoneNumberSchema),
  });

  const renderMessage = () => {
    if (apiError) {
      return <span className="text-xs font-bold text-red-500">{apiError}</span>;
    }
    if (errors.phoneNumber) {
      return (
        <span className="text-xs font-bold text-red-500">
          {errors.phoneNumber?.message}
        </span>
      );
    }
  };

  const renderProgress = () => {
    let message = `${data?.remaining} to go to unlock raffle & lotto`;
    const isComplete = data?.remaining === 0;

    if (data?.remaining === 0) {
      message = "RAFFLE AND LOTTO NOW UNLOCKED";
    }
    const lockImage = !isComplete ? (
      <img className="w-6 h-6" src={LockImage} alt="padlock" />
    ) : null;
    const claimButton = isComplete ? (
      <button
        className="w-full px-4 py-2 bg-red-500 rounded-lg"
        onClick={onClaimTicketsPressed}
      >
        <span className="font-bold text-white">CLAIM YOUR TICKETS HERE</span>
      </button>
    ) : null;

    return (
      <div className="flex flex-col items-center w-full gap-4">
        <div className="flex items-center w-full">
          <div className="relative w-full p-2 rounded-full bg-lotto-dark-green h-9">
            <span className="absolute inset-0 z-10 flex items-center justify-center text-white">
              {message}
            </span>
            <div
              className="absolute top-0 bottom-0 left-0 z-0 h-full rounded-full bg-lotto-orange"
              style={{
                width: `${data?.percentage}%`,
              }}
            ></div>
          </div>
          {lockImage}
        </div>
        {claimButton}
      </div>
    );
  };

  return (
    <section
      style={{
        backgroundImage: `url(${Background})`,
      }}
      className="flex flex-col items-center gap-10 px-4 py-20 bg-cover md:px-20 md:py-40"
    >
      <div className="flex flex-col items-center gap-4 md:max-w-md">
        <span className="font-bold text-8xl text-lotto-bright-orange">
          {data?.eligible_stores.length}
          <span className="text-3xl text-white">/80</span>
        </span>
        <p className="text-xl text-center text-white">
          Community Leaders are now eligible to get lotto and raffle tickets!
        </p>
        {renderProgress()}
      </div>
      {renderTicketGrids()}
      <div className="flex flex-col gap-4 md:max-w-md">
        <p className="text-2xl text-white">
          Enter your Sarisuki registered number below and check if you are
          eligible to participate
        </p>
        <form
          onSubmit={handleSubmit(onMobileNumberSubmitted)}
          className="flex flex-col items-center gap-4 md:flex-row"
        >
          <label className="flex flex-1 w-full h-full mt-1 transition duration-300 bg-white border border-gray-300 rounded-md focus-within:ring-2 ring-lotto-green">
            <input
              {...register("phoneNumber")}
              className="w-full p-2 bg-transparent border-0 focus:ring-0 focus:outline-none"
              placeholder="Enter mobile number"
            />
          </label>
          <button className="w-full px-3 py-2 rounded-lg bg-lotto-orange md:h-full md:w-min md:px-5">
            <span className="font-semibold text-white">
              {isLoading ? "Checking..." : "Search"}
            </span>
          </button>
          {renderMessage()}
        </form>
      </div>
    </section>
  );
};

export default ProgressSection;
