import LogoImageLarge from "../assets/images/logo-large.png";
import LogoImage from "../assets/images/logo.png";

interface IProps {
  isLarge?: boolean;
  className?: string;
}

export const Logo = ({ className, isLarge = false }: IProps) => {
  if (isLarge) {
    return <img className={className} src={LogoImageLarge} alt="logo" />;
  }
  return <img className={className} src={LogoImage} alt="logo" />;
};
