import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiResponse } from "../../../types/api_response";

interface LoginDetails {
  phoneNumber: string;
  password: string;
}

export const checkAuth = createAsyncThunk("auth/checkAuth", async () => {
  const response = await axios.get("/api/authenticate/validate");
  const rawData = response.data;

  if (!rawData.data) {
    throw Error(rawData.message);
  }

  return rawData.data;
});

export const forgotPassword = createAsyncThunk<ApiResponse, string>(
  "auth/forgotPassword",
  async (phoneNumber) => {
    const data = {
      mobile: phoneNumber,
    };
    const response = await axios.post("/api/authenticate/forgot", data);
    return response.data as ApiResponse;
  }
);

export const login = createAsyncThunk<string, LoginDetails>(
  "auth/login",
  async (details: LoginDetails) => {
    const response = await axios.post(`/api/authenticate/`, {
      mobile: details.phoneNumber,
      password: details.password,
    });

    const rawData = response.data;
    console.log(rawData);
    if (!rawData.data) {
      throw Error(rawData.message);
    }

    return rawData.data;
  }
);
export const logout = createAction("auth/logout");
