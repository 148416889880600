import { Redirect, useHistory } from "react-router";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  getLottoTickets,
  resetSelectedDigits,
  selectLotto,
} from "./lottoSlice";
import { GenericButton } from "../../components/Buttons";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { ClaimedLottoTicketCard } from "./components/ClaimedLottoTicketCard";

const ViewLottoTicketPage = () => {
  const history = useHistory();
  const { isLoading, success, lottoTickets, error } =
    useAppSelector(selectLotto);
  const dispatch = useAppDispatch();

  useEffect(() => {
    toast.error(error);
  }, [error]);
  useEffect(() => {
    dispatch(getLottoTickets());
  }, [dispatch]);

  if (isLoading && !success) {
    return null;
  }

  if (lottoTickets.length === 0) {
    return <Redirect to="/lotto" />;
  }

  const onBackToHomePressed = () => {
    // Reset the stored numbers
    dispatch(resetSelectedDigits());
    history.push("/lotto");
  };

  const renderLottoTickets = () => {
    return lottoTickets.map((t) => (
      <ClaimedLottoTicketCard ticket={t} key={t.id} />
    ));
  };

  return (
    <div className="flex justify-center w-full h-full md:p-20">
      <div className="flex flex-col items-center w-full gap-8 p-8 overflow-y-auto md:overflow-hidden md:max-w-md">
        <h1 className="text-2xl font-bold text-center md:text-4xl">
          Your lotto ticket/s:
        </h1>
        <div className="flex flex-col flex-1 gap-8 md:overflow-y-auto">
          {renderLottoTickets()}
        </div>

        <GenericButton
          onClick={onBackToHomePressed}
          className="w-full rounded-md md:w-52"
        >
          <span className="font-bold text-white">Back to Home</span>
        </GenericButton>
      </div>
    </div>
  );
};

export default ViewLottoTicketPage;
