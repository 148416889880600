import { useAppSelector } from "../../app/hooks";
import LogoLarge from "../../assets/images/logo-large.png";
import { selectHome } from "./homeSlice";

const LandingSection = () => {
  const { data } = useAppSelector(selectHome);

  return (
    <section className="grid w-full grid-cols-1 px-4 md:grid-cols-2 md:px-20 md:py-20">
      <div className="flex items-center justify-center">
        <img className="w-72 h-72 md:w-96 md:h-96" src={LogoLarge} alt="Logo" />
      </div>
      <div className="flex flex-col gap-4">
        <h1 className="text-3xl font-bold md:text-6xl">
          Join Sarisuki’s Super Level Up Lotto & Raffle!
        </h1>
        <p className="md:text-2xl">
          Get 80 community leaders to hit ₱80,000 in sales for October and
          unlock our Lotto and Raffle event
        </p>
        <br />
        <p className="md:text-2xl">
          Lotto and Raffle Draw on
          <span className="font-bold text-red-500">
            &nbsp; November 7, 2021 (2:30 PM)
          </span>
        </p>
        <div className="flex justify-between w-full md:w-min md:gap-4">
          <div className="flex flex-col items-center gap-4">
            <div className="flex items-center justify-center w-20 h-20 p-4 rounded-xl bg-lotto-orange">
              <span className="text-2xl font-bold text-white">
                {data?.days}
              </span>
            </div>
            <span>days</span>
          </div>
          <span className="mt-4 text-4xl font-bold text-gray-500">:</span>
          <div className="flex flex-col items-center gap-4">
            <div className="flex items-center justify-center w-20 h-20 p-4 rounded-xl bg-lotto-orange">
              <span className="text-2xl font-bold text-white">
                {data?.hours}
              </span>
            </div>
            <span>hours</span>
          </div>
          <span className="mt-4 text-4xl font-bold text-gray-500">:</span>
          <div className="flex flex-col items-center gap-4">
            <div className="flex items-center justify-center w-20 h-20 p-4 rounded-xl bg-lotto-orange">
              <span className="text-2xl font-bold text-white">
                {data?.minutes}
              </span>
            </div>
            <span>minutes</span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LandingSection;
