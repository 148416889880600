import { Store } from "../homeSlice/types";
interface Props {
  label: string;
  stores: Store[];
}

export const HomeTicketGrid = ({ label, stores }: Props) => {
  const renderTicketNumbers = () => {
    return stores.map((store) => (
      <div
        className="flex items-center justify-center p-4 bg-white rounded-2xl"
        key={store.store_id}
      >
        <span className="font-semibold text-center text-lotto-orange">
          #{store.store_id}
        </span>
      </div>
    ));
  };
  return (
    <div className="flex flex-col w-full gap-4">
      <div className="flex flex-col w-full gap-4 md:flex-row md:items-center md:gap-20">
        <div className="flex items-center justify-between gap-3 md:flex-col md:justify-start md:items-start ">
          <span className="font-bold text-white md:text-4xl">{label}</span>
          <div className="px-3 py-2 rounded-full bg-lotto-dark-green md:px-4">
            <span className="text-sm font-semibold text-white md:text-xl">
              {stores.length} CLs
            </span>
          </div>
        </div>
        <div className="grid items-center w-full grid-cols-3 gap-6 p-4 overflow-y-auto rounded-xl bg-lotto-dark-green max-h-52 md:grid-cols-6">
          {renderTicketNumbers()}
        </div>
      </div>
    </div>
  );
};
