import Blob from "../../assets/images/blob-violet.svg";

const HowToIncreaseSection = () => {
  return (
    <section className="flex flex-col w-full py-10 h-full items-center ">
      <div className="flex flex-col w-full items-center gap-6">
        <h1 className="font-bold text-4xl text-center px-4">
          How can you increase your chances of winning
        </h1>
        <p className="text-gray-500 text-center text-2xl px-4">
          For each additional ₱10,000 sales on top of the ₱80,000, you get
          ADDITIONAL:
        </p>
        <div
          className="p-4 md:px-8 flex items-center justify-center w-full flex-col gap-4 bg-cover md:max-w-xl md:bg-contain md:bg-no-repeat h-96 bg-center "
          style={{
            backgroundImage: `url(${Blob})`,
          }}
        >
          <div className="flex items-center gap-8  text-lotto-bright-yellow font-bold text-2xl md:text-4xl">
            <span className="text-center ">
              1 RAFFLE <br /> TICKET
            </span>
            <span className="text-center">+</span>
            <span className="text-center ">
              1 LOTTERY <br />
              TICKET
            </span>
          </div>
          <p className="text-white text-center md:text-2xl ">
            Ex. ₱90,000 October sales=
            <span className="font-bold">2 RAFFLE & 2 LOTTERY tickets</span>
          </p>
        </div>
      </div>
    </section>
  );
};

export default HowToIncreaseSection;
