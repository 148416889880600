import _ from "lodash";
import classNames from "classnames";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { selectLotto, setSelectedDigit } from "../lottoSlice";

interface IProps {
  ticketNumber: string;
  onChange?: (numbers: number[]) => void;
}

export const LottoNumberPicker = (props: IProps) => {
  const { selectedDigits } = useAppSelector(selectLotto);
  const dispatch = useAppDispatch();

  const onLottoNumberPressed = (index: number) => {
    dispatch(setSelectedDigit(index));
  };

  const renderLottoNumbers = () => {
    return _.range(21).map((i) => {
      const isActive = selectedDigits.find((num) => num === i) !== undefined;
      return (
        <div
          onClick={() => onLottoNumberPressed(i)}
          className={classNames([
            "cursor-pointer rounded-lg transition duration-300 flex items-center justify-center w-10 h-10 bg-white",
            isActive
              ? "bg-lotto-orange text-white hover:bg-opacity-80"
              : "  hover:bg-gray-200",
          ])}
          key={i}
        >
          {i}
        </div>
      );
    });
  };

  return (
    <div className="flex flex-col">
      <div className="px-2 py-3 rounded-t-lg bg-lotto-bright-orange">
        <span className="font-bold">{`Lotto Ticket #${props.ticketNumber}`}</span>
      </div>
      <div className="grid grid-cols-7 gap-3 p-4 rounded-b-lg bg-lotto-gray">
        {renderLottoNumbers()}
      </div>
    </div>
  );
};
