import { HTMLProps } from "react";
import { FieldError, UseFormRegisterReturn } from "react-hook-form";
interface IProps extends HTMLProps<HTMLInputElement> {
  classNames?: string;
  label: string;
  register: UseFormRegisterReturn;
  error?: FieldError;
}

export const PhoneTextField = ({
  label,
  register,
  error,
  ...restProps
}: IProps) => {
  return (
    <div className="flex flex-col  gap-3">
      <span className="text-sm text-gray-500">{label}</span>
      <label className="flex mt-1 focus-within:ring-2 ring-lotto-green transition duration-300 rounded-md">
        <span className="border border-r-0 rounded-tl-md rounded-bl-md px-3 py-2">
          +63
        </span>
        <input
          {...register}
          type="text"
          className="focus:ring-0 focus:border-gray-300 border-1 border-gray-300 w-full rounded-tr-md rounded-br-md"
          {...restProps}
        />
      </label>
      <span className="text-xs text-red-400">{error?.message}</span>
    </div>
  );
};
