import { useEffect } from "react";

import { Route, RouteProps } from "react-router";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { checkAuth, selectAuth } from "../features/auth/authSlice";

interface IProps extends RouteProps {}

const PrivateRoute = (props: IProps) => {
  const { isLoading, isLoggedIn } = useAppSelector(selectAuth);
  const dispatch = useAppDispatch();

  useEffect(() => {
    // If there is a session id in the cookies
    // then we can say that the user is logged in
    dispatch(checkAuth());
  }, [dispatch]);

  if (isLoading) {
    return null;
  }

  if (!isLoading && !isLoggedIn) {
    return (
      <div className="flex items-center justify-center w-full h-screen">
        <span className="text-4xl font-bold text-lotto-green">
          You are unauthorized to view this page.
        </span>
      </div>
    );
  }
  return <Route {...props} />;
};

export default PrivateRoute;
