import TicketImage from "../../../assets/images/ticket.svg";

interface IProps {
  ticketId: string;
}
export const Ticket = ({ ticketId }: IProps) => {
  return (
    <div className=" relative">
      <div className="absolute bottom-0 left-0 right-0 text-center mb-8 font-medium text-lotto-orange text-xs">
        {ticketId}
      </div>
      <img className=" inset-0" src={TicketImage} alt="ticket bg" />
    </div>
  );
};
