import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import ChangePasswordPage from "./ChangePasswordPage";
import ForgotPasswordPage from "./ForgotPasswordPage";
import LoginPage from "./LoginPage";

const Auth = () => {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.path}/login`} component={LoginPage} />
      <Route path={`${match.path}/forgot`} component={ForgotPasswordPage} />
      {/* <Route
        path={`${match.path}/change-password`}
        component={ChangePasswordPage}
      /> */}
      <Redirect to={`${match.path}/login`} />
    </Switch>
  );
};

export default Auth;
