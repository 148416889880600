import Axios from "axios";
import Config from "./config";

const axios = Axios.create({
  baseURL: process.env.NODE_ENV === "production" ? Config.HOST_API : "",
  withCredentials: true,
  xsrfCookieName: "csrftoken",
  xsrfHeaderName: "X-CSRFToken",
});
export default axios;
