import { createReducer } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { checkStoreEligibility, getHomePageData } from ".";
import { ApiResponse } from "../../../types/api_response";
import { HomePageData } from "./types";

export interface HomeState {
  isLoading: boolean;
  error?: any;
  success?: ApiResponse | null;
  data?: HomePageData | null;
}

const initialState: HomeState = {
  data: null,
  isLoading: false,
  error: null,
};

export const homeReducer = createReducer(initialState, {
  [getHomePageData.pending.type]: (state) => {
    state.isLoading = true;
  },
  [getHomePageData.fulfilled.type]: (state, { payload }) => {
    state.data = payload as HomePageData;
    state.isLoading = false;
  },
  [getHomePageData.rejected.type]: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
    toast(String(action.error.message), {
      type: "error",
    });
  },
  [checkStoreEligibility.pending.type]: (state, action) => {
    state.isLoading = false;
  },
  [checkStoreEligibility.fulfilled.type]: (state) => {
    state.isLoading = false;
  },
  [checkStoreEligibility.rejected.type]: (state, action) => {
    state.isLoading = false;
    state.error = action.error.message;
  },
});
