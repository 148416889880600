import { LottoTicket } from "../types/LottoTicket";
import ViewTicketBGFooter from "../../../assets/images/view-ticket-footer.png";
import Star from "../../../assets/images/star.svg";
interface IProps {
  ticket: LottoTicket;
}

export const ClaimedLottoTicketCard = ({ ticket }: IProps) => {
  const renderSelectedNumbers = () => {
    return ticket.lotto_numbers.map((num) => {
      return (
        <div className="relative" key={num}>
          <img src={Star} alt="" />
          <span className="absolute inset-0 flex items-center justify-center font-bold">
            {num}
          </span>
        </div>
      );
    });
  };

  return (
    <div className="relative flex flex-col w-full gap-3 bg-lotto-gray">
      <div className="flex flex-col items-center gap-4">
        <span className="font-bold">Here are your chosen numbers</span>
        <div className="flex gap-3">{renderSelectedNumbers()}</div>
      </div>
      <span className="absolute text-xs font-bold text-white bottom-2 right-2">
        Ticket#{ticket.ticket_number}
      </span>
      <img src={ViewTicketBGFooter} alt="footer" />
    </div>
  );
};
