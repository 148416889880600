import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { forgotPasswordSchema } from "../schemas/forgotPasswordSchema";

import { PhoneTextField } from "../../components/Inputs/PhoneTextField";
import { GenericButton } from "../../components/Buttons";
import { Logo } from "../../components/Logo";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { forgotPassword, selectAuth } from "./authSlice";
import { toast } from "react-toastify";
import { useState } from "react";
import { useHistory } from "react-router";
import { useTimeout } from "rooks";

interface ForgotPasswordInput {
  phoneNumber: string;
}

const ForgotPasswordPage = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ForgotPasswordInput>({
    resolver: yupResolver(forgotPasswordSchema),
  });
  const { isLoading } = useAppSelector(selectAuth);
  const [message, setMessage] = useState<string | null>(null);
  const history = useHistory();

  const dispatch = useAppDispatch();

  const { start } = useTimeout(() => history.push("/auth/login"), 2000);

  const onSubmit = async (data: ForgotPasswordInput) => {
    try {
      const apiResponse = await dispatch(
        forgotPassword(data.phoneNumber)
      ).unwrap();
      reset();
      setMessage(apiResponse.data);
      toast.info("Redirecting to login page");
      start();
    } catch (err) {
      reset();
      toast.error(String(err));
    }
  };
  const renderNotification = () => {
    if (!message) {
      return;
    }
    return (
      <div className="p-4 rounded-xl bg-cyan-50">
        <p className="font-bold text-cyan-700">{message}</p>
      </div>
    );
  };

  return (
    <div className="flex flex-col items-center w-full p-4 pt-20">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col items-center w-full h-full gap-4 md:max-w-sm"
      >
        <Logo />
        <h1 className="text-xl font-bold md:text-3xl">Forgot Password</h1>
        {renderNotification()}
        <div className="flex flex-col w-full gap-4">
          <PhoneTextField
            label="Sarisuki registered number"
            register={register("phoneNumber")}
            type="text"
            error={errors.phoneNumber}
          />
        </div>
        <div className="flex-1 md:hidden"></div>
        <GenericButton type="submit" className="w-full md:w-52">
          <span className="font-bold text-white">
            {isLoading ? "Loading..." : " Submit"}
          </span>
        </GenericButton>
      </form>
    </div>
  );
};

export default ForgotPasswordPage;
