import { HTMLProps } from "react";
import { FieldError, UseFormRegisterReturn } from "react-hook-form";
interface IProps extends HTMLProps<HTMLInputElement> {
  classNames?: string;
  label: string;
  register: UseFormRegisterReturn;
  error?: FieldError;
}

export const TextField = ({ label, register, error, ...restProps }: IProps) => {
  return (
    <div className="flex flex-col">
      <span className="text-sm text-gray-500">{label}</span>
      <label className="flex mt-1 focus-within:ring-2 ring-lotto-green transition duration-300 rounded-md border border-gray-300">
        <input
          {...register}
          className="focus:ring-0 border-0 bg-transparent"
          {...restProps}
        />
      </label>
      <span className="text-red-400 text-xs ">{error?.message}</span>
    </div>
  );
};
