import { Router, Switch, Route } from "react-router";
import Layout from "../components/Layout";
import Auth from "../features/auth/Auth";
import Lotto from "../features/lotto/Lotto";
import HomePage from "../features/home/HomePage";
import { Provider } from "react-redux";
import { store } from "./store";
import PrivateRoute from "../components/PrivateRoute";
import ModalManager from "../features/modal/ModalManager";
import { ToastContainer } from "react-toastify";
import history from "../utils/history";
function App() {
  return (
    <Provider store={store}>
      <Layout>
        <Router history={history}>
          <Switch>
            <Route exact path="/" component={HomePage} />
            <PrivateRoute path="/lotto" component={Lotto} />
            <Route path="/auth" component={Auth} />
          </Switch>
          <ModalManager />
        </Router>
      </Layout>
      <ToastContainer
        hideProgressBar={true}
        newestOnTop
        autoClose={3000}
        position="bottom-center"
      />
    </Provider>
  );
}

export default App;
