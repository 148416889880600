import { ReactNode } from "react";
import classNames from "classnames";
interface IProps {
  children?: ReactNode;
  className?: string;
}

const Layout = (props: IProps) => {
  return (
    <div className={classNames(["flex font-inter h-screen", props.className])}>
      {props.children}
    </div>
  );
};

export default Layout;
