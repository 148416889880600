import { OutlineButton } from "../../../components/Buttons";
import ArrowRight from "../../../assets/images/arrow-right.svg";

interface IProps {
  claimableLottoTickets: number;
  onClick: () => void;
}

export const EnterLottoNumbersButton = ({
  claimableLottoTickets,
  onClick,
}: IProps) => {
  if (claimableLottoTickets === 0) {
    return null;
  }
  return (
    <OutlineButton
      onClick={onClick}
      className="flex justify-between p-3 transition duration-300 border border-gray-300 rounded-lg hover:bg-gray-200 focus:border-lotto-green"
    >
      <span>Enter my lotto numbers</span>
      <img src={ArrowRight} alt="arrow right" />
    </OutlineButton>
  );
};
