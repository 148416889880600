import * as yup from "yup";
import { phNumberWith0Regex } from "../../consts/regex";

export const phoneNumberSchema = yup.object({
  phoneNumber: yup
    .string()
    .required("This field is required!")
    .matches(
      phNumberWith0Regex,
      "Please enter a valid phone number: (e.g 09231323143)"
    ),
});
