import { useEffect } from "react";
import { Redirect, useHistory } from "react-router";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { GenericButton, OutlineButton } from "../../components/Buttons";
import { TextButton } from "../../components/Buttons/TextButton";
import { showModal } from "../modal/modalSlice";
import { LottoNumberPicker } from "./components/LottoNumberPicker";
import {
  getLottoTickets,
  resetSelectedDigits,
  selectLotto,
} from "./lottoSlice";
import { ReactSVG } from "react-svg";
import { ArrowRight, ChevronRight } from "../../components/Icons";

const FillInTicketPage = () => {
  const {
    isLoading,
    success,
    error,
    lottoTickets,
    claimableLottoTickets,
    selectedDigits,
  } = useAppSelector(selectLotto);
  const dispatch = useAppDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getLottoTickets());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      toast(error, {
        type: "error",
      });
    }
  }, [error]);

  if (isLoading && !success) {
    return null;
  }

  const hasClaimableTicket = claimableLottoTickets > 0;

  if (!hasClaimableTicket) {
    return <Redirect to="/lotto" />;
  }

  const totalClaimableTickets = claimableLottoTickets + lottoTickets.length;
  const currentTicket = lottoTickets.length + 1;
  const onBackToHomePressed = () => {
    dispatch(resetSelectedDigits());
    history.push("/lotto");
  };

  const onSubmitPressed = () => {
    const isValid = selectedDigits.length === 3;

    if (!isValid) {
      alert("Please choose 3 digits");
      return;
    }
    dispatch(
      showModal({
        modalName: "lotto_confirm",
        modalProps: {
          allowOutsideClicked: true,
        },
      })
    );
  };

  return (
    <div className="flex justify-center w-full h-full gap-8 p-4 md:p-8">
      <div className="flex flex-col w-full max-w-md gap-6">
        <TextButton
          onClick={onBackToHomePressed}
          className="flex items-center gap-4 text-gray-500 w-min"
        >
          <ArrowRight className="text-gray-500 transform rotate-180 stroke-current" />
          <span>Back</span>
        </TextButton>
        <span className="text-2xl font-bold text-lotto-orange">
          TICKET {currentTicket} OUT OF {totalClaimableTickets}
        </span>
        <h1 className="text-2xl font-bold">Fill up your lotto ticket</h1>
        <p className="text-gray-400">Choose 3 digits for each ticket.</p>
        <LottoNumberPicker ticketNumber={currentTicket.toString()} />
        <div className="flex flex-col w-full gap-4">
          <GenericButton onClick={onSubmitPressed} className="w-full md:w-52">
            <span className="font-bold text-white">Submit</span>
          </GenericButton>
        </div>
      </div>
    </div>
  );
};

export default FillInTicketPage;
