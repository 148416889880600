import classNames from "classnames";
import { useAppSelector } from "../../app/hooks";
import { LottoConfirmationModal } from "../lotto/components/modals/LottoConfirmationModal";
import { selectModal } from "./modalSlice";

const ModalManager = () => {
  const { isVisible, modalProps, modalName } = useAppSelector(selectModal);

  const renderModal = () => {
    switch (modalName) {
      case "lotto_confirm":
        return <LottoConfirmationModal />;
    }
  };

  return (
    <div
      className={classNames([
        " h-screen w-screen bg-gray-500 bg-opacity-50  inset-0 z-10",
        isVisible ? "fixed" : "hidden",
      ])}
    >
      {renderModal()}
    </div>
  );
};

export default ModalManager;
