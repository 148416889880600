import RightArrow from "../../../assets/images/arrow-right.svg";
import { BaseTicket } from "../types/Ticket";

interface IProps {
  label: string;
  tickets: BaseTicket[];
  isRaffleTicket: boolean;
  onClick: () => void;
}
export const ClaimableTicketsCard = ({
  label,
  tickets,
  isRaffleTicket,
  onClick,
}: IProps) => {
  if (tickets.length === 0) {
    return null;
  }

  const formattedTicketIds = tickets
    .map((t) =>
      isRaffleTicket ? `SS-${t.ticket_number}` : `#${t.ticket_number}`
    )
    .join(", ");

  return (
    <div
      onClick={onClick}
      className="flex flex-col w-full p-4 transition duration-300 bg-white border border-gray-400 rounded-lg cursor-pointer hover:bg-gray-200"
    >
      <div className="flex justify-between">
        <span className="text-2xl font-bold text-lotto-green">{label}</span>
        <img src={RightArrow} alt="Right Arrow" />
      </div>
      <span className="text-gray-400">{formattedTicketIds}</span>
    </div>
  );
};
