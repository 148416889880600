import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiResponse } from "../../../types/api_response";

export const getHomePageData = createAsyncThunk(
  "home/getHomePageData",
  async () => {
    // Start fetching for the homepage data
    const response = await axios.get(`api/lotto/frontpage/`);

    return response.data;
  }
);
export const checkStoreEligibility = createAsyncThunk<ApiResponse, string>(
  "home/checkStoreEligibility",
  async (phoneNumber) => {
    // Remove the 0 from the phoneNumber
    const parsedPhoneNumber = phoneNumber.slice(1);
    const response = await axios.get(
      `/api/lotto/eligible/${parsedPhoneNumber}/`
    );
    return response.data as ApiResponse;
  }
);
