import classNames from "classnames";
import { ButtonHTMLAttributes, ReactNode } from "react";

interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children?: ReactNode;
}

export const GenericButton = ({
  children,
  className,
  ...restProps
}: IProps) => {
  return (
    <button
      {...restProps}
      className={classNames([
        "rounded-lg bg-lotto-green px-4 py-3 focus:outline-none focus:bg-opacity-80",
        className,
      ])}
    >
      {children}
    </button>
  );
};
