import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { loginSchema } from "../schemas/loginSchema";
import { TextField } from "../../components/Inputs/TextField";
import { PhoneTextField } from "../../components/Inputs/PhoneTextField";
import { GenericButton } from "../../components/Buttons";
import { Logo } from "../../components/Logo";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { login, selectAuth } from "./authSlice";
import { useHistory } from "react-router";
import { useEffect } from "react";
import { toast } from "react-toastify";

interface LoginFormInput {
  phoneNumber: string;
  password: string;
}

const LoginPage = () => {
  const { isLoading, isLoggedIn, error } = useAppSelector(selectAuth);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const onSubmit = async (data: LoginFormInput) => {
    await dispatch(login(data));
  };

  // Handle Error Messages
  useEffect(() => {
    if (!error) {
      return;
    }
    toast(error, {
      type: "error",
    });
  }, [error]);
  useEffect(() => {
    if (!isLoading && isLoggedIn) {
      // Redirect the user to the lotto page
      history.push("/lotto");
    }
  }, [history, isLoading, isLoggedIn]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormInput>({
    resolver: yupResolver(loginSchema),
  });

  const renderTitle = () => {
    // Start handling state changes

    return (
      <p className="hidden text-sm text-gray-500 md:block">
        Enter your credentials from the CL App
      </p>
    );
  };

  const renderButton = () => {
    if (isLoading) {
      return (
        <GenericButton type="submit" className="w-full md:w-52">
          <span className="font-bold text-white">Loading...</span>
        </GenericButton>
      );
    }

    return (
      <GenericButton type="submit" className="w-full md:w-52">
        <span className="font-bold text-white">Login</span>
      </GenericButton>
    );
  };

  return (
    <div className="flex flex-col items-center w-full h-full p-4 pt-20">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col items-center w-full h-full gap-4 md:max-w-sm"
      >
        <Logo />
        <h1 className="text-xl font-bold md:text-3xl">
          Lotto and Raffle Log In
        </h1>
        {renderTitle()}
        <div className="flex flex-col w-full gap-4">
          <PhoneTextField
            label="Sarisuki registered number"
            register={register("phoneNumber")}
            type="text"
            error={errors.phoneNumber}
          />
          <TextField
            register={register("password")}
            label="Password"
            type="password"
            error={errors.password}
          />
        </div>
        <div className="flex-1 md:hidden"></div>
        {renderButton()}
        <a href="forgot" className="text-gray-400">
          Forgot password
        </a>
      </form>
    </div>
  );
};

export default LoginPage;
