import * as yup from "yup";
import { phNumberRegex } from "../../consts/regex";

export const loginSchema = yup.object({
  password: yup.string().required("This field is required!"),
  phoneNumber: yup
    .string()
    .matches(phNumberRegex, "Please enter a valid number: (e.g 912345678)")
    .required("This field is required!"),
});
