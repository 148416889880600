import { configureStore } from "@reduxjs/toolkit";
import { authReducer } from "../features/auth/authSlice";
import { lottoReducer } from "../features/lotto/lottoSlice";
import { modalReducer } from "../features/modal/modalSlice";
import { homeReducer } from "../features/home/homeSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    modal: modalReducer,
    lotto: lottoReducer,
    home: homeReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
