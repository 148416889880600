import SlotMachineGraphic from "../../assets/images/slot-machine.svg";

const EndSection = () => {
  return (
    <section className="flex flex-col w-full py-10 items-center px-4 gap-4 bg-lotto-bright-yellow ">
      <div className="flex flex-col w-full md:max-w-md">
        <h1 className="text-4xl font-black text-center">
          Tara na!
          <br />
          Benta na Ka-Sari!
        </h1>
        <img
          className="w-full"
          src={SlotMachineGraphic}
          alt="slot machine graphic"
        />
      </div>
    </section>
  );
};

export default EndSection;
