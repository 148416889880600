const Config = {
  MAIN_HOST_API: process.env.REACT_APP_MAIN_API_HOST,
  HOST_API:
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_API_HOST
      : "http://localhost:8000",
  X_KEY: process.env.REACT_APP_XKEY,
  APP_ID: process.env.REACT_APP_APP_ID,
};

export default Config;
