import { useHistory } from "react-router";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { GenericButton } from "../../../../components/Buttons";
import {
  resetSelectedDigits,
  selectLotto,
  submitLottoNumber,
} from "../../lottoSlice";
import { hideModal } from "../../../modal/modalSlice";

export const LottoConfirmationModal = () => {
  const { selectedDigits, claimableLottoTickets } = useAppSelector(selectLotto);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const onSubmitPressed = async () => {
    if (claimableLottoTickets === 0) {
      // There no lotto tickets to claim
      // redirect use to view
      await history.push("/lotto/view");
      return;
    }
    // Otherwise refresh the page
    await dispatch(submitLottoNumber(selectedDigits));
    dispatch(resetSelectedDigits());
    dispatch(hideModal());
  };

  const onChangeNumbersPressed = () => {
    dispatch(hideModal());
  };

  const renderContent = () => {
    if (selectedDigits.length === 0) {
      return <div>You have no selected numbers please</div>;
    }
    const formattedNumbers = `(${selectedDigits[0]}, ${selectedDigits[1]}, ${selectedDigits[2]})`;
    return (
      <>
        <div className="flex flex-col items-center ">
          <h1 className="text-xl font-bold text-center md:text-3xl">
            You are about to submit your lotto ticket.
          </h1>
          <p className="text-center">
            Make sure your selected numbers {formattedNumbers} are final. You
            may not change or edit these numbers after submitting.
          </p>
        </div>
        <GenericButton onClick={onSubmitPressed} className="w-1/2">
          <span className="font-bold text-white">Submit</span>
        </GenericButton>
        <button onClick={onChangeNumbersPressed} className="focus:outline-none">
          Change my lotto numbers
        </button>
      </>
    );
  };

  return (
    <div className="flex items-center justify-center w-full h-full gap-8 p-4 md:items-start items md:p-8 ">
      <div className="flex flex-col items-center gap-8 px-8 py-20 bg-white rounded-lg md:max-w-xl">
        {renderContent()}
      </div>
    </div>
  );
};
