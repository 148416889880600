import { useEffect } from "react";
import { useHistory } from "react-router";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { GenericButton } from "../../components/Buttons";
import { Ticket } from "./components/Ticket";
import { getRaffleTickets, selectLotto } from "./lottoSlice";

const ViewRaffleTicketsPage = () => {
  const { isLoading, raffleTickets } = useAppSelector(selectLotto);
  const history = useHistory();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getRaffleTickets());
  }, [dispatch]);

  if (isLoading) {
    return null;
  }

  const onBackToHomePressed = () => {
    history.push("/lotto");
  };

  const renderTickets = () => {
    return raffleTickets.map((t) => (
      <Ticket ticketId={`SS-${t.ticket_number}`} key={t.ticket_number} />
    ));
  };

  return (
    <div className="flex flex-col items-center w-full h-full gap-8 p-8">
      <h1 className="text-2xl font-bold">Your raffle tickets:</h1>

      <div className="hidden grid-cols-3 gap-8 overflow-y-auto md:grid">
        {renderTickets()}
      </div>
      <div className="flex flex-col items-center w-full h-full gap-8 py-8 overflow-y-auto md:hidden">
        {renderTickets()}
      </div>

      <div className="flex-1 md:hidden"></div>
      <GenericButton onClick={onBackToHomePressed} className="w-full md:w-52">
        <span className="font-bold text-white">Back to Home</span>
      </GenericButton>
    </div>
  );
};

export default ViewRaffleTicketsPage;
