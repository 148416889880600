import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiResponse } from "../../../types/api_response";
import axios from "../../../utils/axios";
import { LottoTicket } from "../types/LottoTicket";

export const setSelectedDigit = createAction<number>("lotto/setSelectedDigit");
export const resetSelectedDigits = createAction("lotto/resetSelectedNumber");

export const getLottoTickets = createAsyncThunk(
  "lotto/getLottoTickets",
  async () => {
    const response = await axios.get("/api/lotto/lotto-tickets/");
    return response.data;
  }
);
export const submitLottoNumber = createAsyncThunk<LottoTicket[], number[]>(
  "lotto/submitLottoNumber",
  async (numbers) => {
    const response = await axios.post("/api/lotto/lotto-tickets/", {
      lotto_numbers: numbers,
    });
    return response.data as LottoTicket[];
  }
);
export const getRaffleTickets = createAsyncThunk(
  "lotto/getRaffleTickets",
  async () => {
    const response = await axios.get("/api/lotto/raffle-tickets/");
    return response.data;
  }
);
export const claimRaffleTickets = createAsyncThunk(
  "lotto/claimRaffleTickets",
  async () => {
    const response = await axios.post("/api/lotto/raffle-tickets/");
    return response.data;
  }
);
