import classNames from "classnames";
import { ButtonHTMLAttributes, ReactNode } from "react";

interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children?: ReactNode;
}

export const TextButton = ({ children, className, ...restProps }: IProps) => {
  return (
    <button
      {...restProps}
      className={classNames([
        "focus:outline-none transition duration-300 hover:text-black  rounded-lg",
        className,
      ])}
    >
      {children}
    </button>
  );
};
