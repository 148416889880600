import { Switch, Route, useRouteMatch } from "react-router-dom";
import ViewRaffleTicketsPage from "./ViewRaffleTicketsPage";
import FillInTicketPage from "./FillInPage";
import UserPage from "./UserPage";
import ViewLottoTicketPage from "./ViewLottoTicketPage";

const Lotto = () => {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route
        exact
        path={`${match.path}/raffle-tickets`}
        component={ViewRaffleTicketsPage}
      />
      <Route
        exact
        path={`${match.path}/fill-in`}
        component={FillInTicketPage}
      />

      <Route path={`${match.path}/view`} component={ViewLottoTicketPage} />
      <Route path={`${match.path}/`} component={UserPage} />
    </Switch>
  );
};

export default Lotto;
